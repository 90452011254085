/**
 * 数据中心
 */
/**
 * 活动详情
 */
 export const EVENTDETAIL = [
  {
    event_id: 1163,
    survey: '{"active":"true","version":"0.1","items":[{"type":"radio","field":"csurvey1662526405616","prompt":"我要参加","placeholder":"请选择您要参加的会议性质","default":"","mandatory":"true","options":["线下会议","线上直播"]},{"type":"radio","field":"csurvey1662526467760","prompt":"午餐","placeholder":"请至少是否需要午餐","default":"","mandatory":"true","options":["需要午餐(签到时领取餐券)","不需要午餐"]}]}'
  }
]

/**
 * 论坛列表
 */
export const EVENTCENTER = [
  {
    event_id: 1163,
    branch: 'main',
    title: '主论坛：开源、商业、产业最新趋势与洞见',
    url: 'https://www.slidestalk.com/jssdk/live/1215',
    live_url: 'https://www.slidestalk.com/w/1215',
    cover: '/images/cover-leader.png',
    start_time: '2022-09-24 09:00',
    end_time: '2022-09-24 17:30'
  },
  {
    event_id: 1163,
    branch: '1st',
    title: '分论坛一：大数据架构',
    url: 'https://www.slidestalk.com/jssdk/live/1205',
    live_url: 'https://www.slidestalk.com/w/1205',
    cover: '/images/cover-leader.png',
    start_time: '2022-09-24 13:30',
    end_time: '2022-09-24 17:30'
  },
  {
    event_id: 1163,
    branch: '2nd',
    title: '分论坛二：企业应用',
    url: 'https://www.slidestalk.com/jssdk/live/1206',
    live_url: 'https://www.slidestalk.com/w/1206',
    cover: '/images/cover-leader.png',
    start_time: '2022-09-24 13:30',
    end_time: '2022-09-24 17:30'
  },
  {
    event_id: 1163,
    branch: '3rd',
    title: '分论坛三：云原生架构',
    url: 'https://www.slidestalk.com/jssdk/live/1207',
    live_url: 'https://www.slidestalk.com/w/1207',
    cover: '/images/cover-leader.png',
    start_time: '2022-09-24 13:30',
    end_time: '2022-09-24 17:30'
  },
  {
    event_id: 1163,
    branch: '4th',
    title: '分论坛四：机器学习',
    url: 'https://www.slidestalk.com/jssdk/live/1208',
    live_url: 'https://www.slidestalk.com/w/1208',
    cover: '/images/cover-leader.png',
    start_time: '2022-09-24 13:30',
    end_time: '2022-09-24 17:30'
  },
  {
    event_id: 1163,
    branch: '5th',
    title: '分论坛五：AI数据开放',
    url: 'https://www.slidestalk.com/jssdk/live/1209',
    live_url: 'https://www.slidestalk.com/w/1209',
    cover: '/images/cover-leader.png',
    start_time: '2022-09-24 13:30',
    end_time: '2022-09-24 17:30'
  }
]

/**
 * 嘉 宾及议程
 */
export const DATACENTER = [
  {
    event_id: 1163,
    name: '戴金权',
    avatar: '/images/chairman2.png',
    branch: 'chairman',
    career: '英特尔院士、大数据人工智能首席构架师、大数据分析和人工智能创新院院长',
    intro: ''
  },
  {
    event_id: 1163,
    name: '堵俊平',
    avatar: '/images/chairman1.png',
    branch: 'chairman',
    career: 'Apache Member & Hadoop PMC, LF AI & DATA基金会董事主席，开放原子开源基金会TOC主席',
    intro: ''
  },
  {
    event_id: 1163,
    name: '金耀辉',
    avatar: '/images/chairman3.png',
    branch: 'chairman',
    career: '上海交通大学人工智能研究院教授、博士生导师、上海白玉兰开源开放研究院执行院长',
    intro: ''
  },
  {
    event_id: 1163,
    name: '陈传杰',
    avatar: '/images/commitee1.png',
    branch: 'committee',
    career: 'openLooKeng技术专家',
    intro: ''
  },
  {
    event_id: 1163,
    name: '代立冬',
    avatar: '/images/commitee4.png',
    branch: 'committee',
    career: '白鲸开源联合创始人，Apache DolphinScheduler PMC Chair',
    intro: ''
  },
  {
    event_id: 1163,
    name: '邸 帅',
    avatar: '/images/commitee7.png',
    branch: 'committee',
    career: 'Apache Linkis PPMC, WeDataSphere 社区发起人',
    intro: ''
  },
  {
    event_id: 1163,
    name: '高 丰',
    avatar: '/images/commitee10.png',
    branch: 'committee',
    career: '开放数据中国执行主任、白玉兰开源开放数据顾问',
    intro: '开放数据中国联合创始人兼执行主任，兼任上海白玉兰开源开放研究院开放数据顾问。作为开放数据议题的先锋推动者，他先后被授予耶鲁大学世界学者、第一财经 DT 财经“数据科学 50 人”等荣誉。'
  },
  {
    event_id: 1163,
    name: '顾 荣',
    avatar: '/images/commitee14.png',
    branch: 'committee',
    career: '南京大学副研究员，Fluid开源社区主席，Alluxio项目PMC',
    intro: '南京大学副研究员，Fluid开源社区主席，Alluxio开源项目PMC成员，主要研究方向为云计算与大数据系统、高效缓存/索引系统、边缘计算系统等，主持多项国家基金项目和企业创新项目，发表学术论文40余篇，学术专著1部，成果落地于合作企业以及多个知名开源系统，获江苏省科学技术一等奖、腾讯云最具价值专家、中国尖峰开源人物、南京大学青年五四奖章等，任中国计算机学会高级会员、分布式计算与系统专委、系统软件专委执行委员。'
  },
  {
    event_id: 1163,
    name: '黄向东',
    avatar: '/images/commitee15.png',
    branch: 'committee',
    career: '博士，清华大学助理研究员，ASF Member',
    intro: ''
  },
  {
    event_id: 1163,
    name: '姜 宁',
    avatar: '/images/commitee16.png',
    branch: 'committee',
    career: 'Apache软件基金会董事， ALC Beijing发起人，华为开源管理中心技术专家',
    intro: ''
  },
  {
    event_id: 1163,
    name: '江 波',
    avatar: '/images/commitee17.png',
    branch: 'committee',
    career: 'SegmentFault 思否 COO，开源社理事、执行长',
    intro: ''
  },
  {
    event_id: 1163,
    name: '李 钰',
    avatar: '/images/commitee20.png',
    branch: 'committee',
    career: 'ASF Member, Apache Flink & HBase PMC Member',
    intro: ''
  },
  {
    event_id: 1163,
    name: '李亚坤',
    avatar: '/images/commitee19.png',
    branch: 'committee',
    career: '字节跳动云原生计算技术负责人',
    intro: ''
  },
  {
    event_id: 1163,
    name: '马洪宾',
    avatar: '/images/commitee29.png',
    branch: 'committee',
    career: 'Kyligence 技术合伙人，首席布道师及研究员',
    intro: ''
  },
  {
    event_id: 1163,
    name: '邵赛赛',
    avatar: '/images/commitee21.png',
    branch: 'committee',
    career: '腾讯大数据实时湖仓团队负责人，专家工程师，Apache基金会成员',
    intro: ''
  },
  {
    event_id: 1163,
    name: '谭中意',
    avatar: '/images/commitee24.png',
    branch: 'committee',
    career: '第四范式架构师，LF AI & Data TAC成员， Apache基金会Member',
    intro: ''
  },
  {
    event_id: 1163,
    name: '谢巍盛',
    avatar: '/images/third20.png',
    branch: 'committee',
    career: '翼支付首席数据科学家、人工智能研究院院长',
    intro: '翼支付首席数据科学家, 人工智能研究院院长, 负责数智科技能力建设及产业化工作。'
  },
  {
    event_id: 1163,
    name: '杨政国',
    avatar: '/images/commitee27.png',
    branch: 'committee',
    career: '百度资深工程师，Apache Doirs PMC',
    intro: ''
  },
  {
    event_id: 1163,
    name: '翟 佳',
    avatar: '/images/commitee28.png',
    branch: 'committee',
    career: 'ApachePulsar PMC 成员，StreamNative 联合创始人',
    intro: ''
  },
  {
    event_id: 1163,
    title: '致辞',
    title_order: 100,
    content: '',
    time: '09:30-09:40',
    name: '张延川',
    avatar: '/images/main1.png',
    avatar_order: 1000,
    branch: 'main',
    career: '中国通信学会秘书长',
    intro: ''
  },
  {
    event_id: 1163,
    title: '开源大数据技术的发展脉络与趋势',
    title_order: 200,
    content: '',
    time: '9:40-10:10',
    name: '堵俊平',
    avatar: '/images/chairman1.png',
    avatar_order: 200,
    branch: 'main',
    career: 'Apache Member & Hadoop PMC, LF AI & DATA基金会董事主席，开放原子开源基金会TOC主席',
    intro: '',
    link_url: 'https://www.slidestalk.com/slidestalk/1v1094954?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/1v1094954?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/1v1094954'
  },
  {
    event_id: 1163,
    title: '从笔记本到云 无缝扩展大数据AI',
    title_order: 300,
    content: '',
    time: '10:10-10:30',
    name: '戴金权',
    avatar: '/images/chairman2.png',
    avatar_order: 300,
    branch: 'main',
    career: '英特尔院士、大数据人工智能首席构架师、大数据分析和人工智能创新院院长',
    intro: '',
    link_url: 'https://www.slidestalk.com/slidestalk/2Haoop_meetup_Jason54278?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/2Haoop_meetup_Jason54278?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/2Haoop_meetup_Jason54278'
  },
  {
    event_id: 1163,
    time: '10:30-10:50 ',
    title: '白玉兰开源：从论文到产品的可复现人工智能的思考与尝试',
    title_order: 500,
    content: '',
    name: '金耀辉',
    avatar: '/images/chairman3.png',
    avatar_order: 500,
    branch: 'main',
    career: '上海交通大学人工智能研究院教授、博士生导师、上海白玉兰开源开放研究院执行院长',
    intro: '',
    link_url: 'https://www.slidestalk.com/slidestalk/3forCAHM202230909?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/3forCAHM202230909?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/3forCAHM202230909'
  },
  {
    event_id: 1163,
    title: '云原生平台为大数据应用降本增效',
    title_order: 600,
    content: '',
    time: '10:50-11:10',
    name: '孙开本',
    avatar: '/images/main2.png',
    avatar_order: 700,
    branch: 'main',
    career: 'Ampere Computing 产品市场总监',
    intro: '',
    link_url: 'https://www.slidestalk.com/slidestalk/448551?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/448551?video&embed',
    download_url: null
  },
  {
    event_id: 1163,
    title: '从openLooKeng看大数据未来的趋势与发展',
    title_order: 700,
    content: '',
    time: '11:10-11:30',
    name: '廖登宏',
    avatar: '/images/fouth18.png',
    avatar_order: 600,
    branch: 'main',
    career: '华为高级工程师',
    intro: '长期参与 openLooKeng 项目的需求分析、方案设计、代码开发等工作，致力于大数据领域的高性能查询场景的优化，对openLooKeng的解决方案及其行业应用有深入理解。',
    link_url: 'https://www.slidestalk.com/slidestalk/5openLooKeng90066?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/5openLooKeng90066?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/5openLooKeng90066',
  },
  {
    event_id: 1163,
    title: 'Ask Me Anything',
    content: '大咖时间随便问',
    title_order: 800,
    time: '11:30-12:10',
    name: '',
    avatar: '',
    avatar_order: 9990,
    branch: 'main',
    career: '',
    intro: '',
    is_discuz: true,
    link_url: 'https://www.slidestalk.com/slidestalk/KV40256?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/KV40256?video&embed',
    download_url: null
  },
  {
    event_id: 1163,
    title: '午餐时间',
    title_order: 100,
    time: '12:10-13:30',
    name: '报名后，签到时提供餐券',
    avatar: '',
    avatar_order: 10000,
    branch: 'lunch',
    career: '',
    intro: '',
    is_lunch: true
  },
  {
    event_id: 1163,
    title: '会后留影',
    title_order: 100,
    time: '17:00-17:30',
    name: '照相留念及抽奖',
    avatar: '',
    avatar_order: 10000,
    branch: 'ended',
    career: '',
    intro: '',
    is_ended: true
  },
  {
    event_id: 1163,
    title: 'What\'s new in Apache Spark 3.2 & 3.3',
    title_order: 300,
    content: '介绍Spark新版本的新特性和功能改进。',
    time: '14:30-15:00',
    name: '范文臣',
    leader: true,
    avatar: '/images/second4.png',
    avatar_order: 100,
    branch: '1st',
    career: 'Apache Spark PMC Member',
    intro: 'Databricks 开源组技术主管，Apache Spark PMC Member，Spark社区最活跃的贡献者之一。从2013年开始参与Spark的研发，2015年加入Databricks，目前主要负责Spark Core/SQL 的设计开发和开源社区管理。',
    link_url: 'https://www.slidestalk.com/slidestalk/3DeepDiveintotheNewFeaturesofApacheSpark32and3378134?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/3DeepDiveintotheNewFeaturesofApacheSpark32and3378134?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/3DeepDiveintotheNewFeaturesofApacheSpark32and3378134'
  },
  {
    event_id: 1163,
    title: 'Spark SQL Performance Improvement: Push down Partial aggregate through Join',
    title_order:200,
    content: '在生产中，我们发现有很多连接（Join）后都会有聚合（Aggregate）的查询，并且Join可能会膨胀或者Aggregate会明显减少数据。这里详细介绍了我们对该类查询所做的优化。TPC-DS中30个query有提升，最明显的能提升6倍。 1. Push down partial aggregate through inner Join 2. Push down partial distinct through Join 3. De-duplicate the right side of left semi/anti join 4. Make partial aggregate adaptively。',
    time: '13:30-14:00',
    name: '王玉明',
    avatar: '/images/second21.jpg',
    avatar_order: 700,
    branch: '1st',
    career: 'Apache Spark Committer',
    intro: 'eBay的软件工程师，也是Apache Spark 的committer。他专注于Spark SQL优化。',
    link_url: 'https://www.slidestalk.com/slidestalk/1AggregatePushDownthroughJoinlatest90930?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/1AggregatePushDownthroughJoinlatest90930?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/1AggregatePushDownthroughJoinlatest90930'
  },
  {
    event_id: 1163,
    title: 'What\'s new in Apache Ozone 1.3',
    title_order: 600,
    content: 'Apache Ozone将要发布新的1.3版本。这个版本支持了新功能EC和Container Balancer，以及重要的整体优化，包括文件系统元数据操作优化，数据节点元数据存储优化等。本次分享将具体介绍这些功能和优化的具体实现方案。',
    time: '16:00-16:30',
    name: '陈 怡',
    avatar: '/images/second2.png',
    avatar_order: 200,
    branch: '1st',
    career: 'Apache Ozone PMC 主席',
    intro: '目前就职于Cloudera，长期专注于分布式存储领域',
    link_url: 'https://www.slidestalk.com/slidestalk/5ozonev197636?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/5ozonev197636?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/5ozonev197636'
  },
  {
    event_id: 1163,
    title: '工业物联网时序数据库Apache IoTDB',
    title_order: 700,
    content: '介绍时序数据库与其他数据库的区别，IoTDB的整体框架设计，底层开放的对时序数据优化的文件格式TsFIle，与其它开源生态组件的集成。',
    time: '16:30-17:00',
    name: '田 原',
    avatar: '/images/second10.png',
    avatar_order: 600,
    branch: '1st',
    career: 'Apache IoTDB PMC',
    intro: '硕士毕业于清华大学软件学院，Apache IoTDB PMC，2019年起参与IoTDB社区，Github累计贡献度排名第三，目前致力于Apache IoTDB MPP查询计算引擎的开发。',
    link_url: 'https://www.slidestalk.com/slidestalk/6ApacheIoTDB81884?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/6ApacheIoTDB81884?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/6ApacheIoTDB81884'
  },
  {
    event_id: 1163,
    title: '茶歇',
    title_order: 500,
    time: '15:30-16:00',
    branch: '1st',
    career: '',
    intro: '',
    is_tea: true
  },
  {
    event_id: 1163,
    title: '通用分布式计算引擎RAY',
    title_order: 200,
    content: '随着互联网数据的爆发式增长，越来越多的领域和应用场景需要构建分布式系统。Ray做为通用分布式计算引擎，并没有基于任何一种计算范式，而是从单机编程中简单的function和class等概念出发，提供一套简单易用的API，帮助开发者一站式解决构建分布式系统过程中面临的通信、存储、部署、资源调度、运行时环境、故障恢复等方面的问题。本次演讲将介绍Ray的核心能力，Ray在蚂蚁的应用案例以及Ray开源社区现状。',
    time: '14:00-14:30',
    name: '宋顾杨',
    avatar: '/images/second9.png',
    avatar_order: 500,
    branch: '1st',
    career: '蚂蚁集团技术专家，Ray开源社区Committer',
    intro: '2015年加入蚂蚁集团，一直从事基础架构方面的工作。2017年以来主要参与和推动了Ray在蚂蚁从0到1再到万台服务器规模的落地。近两年活跃在Ray开源社区，作为Ray C++开发框架的发起人和Runtime Environments框架的主要开发者，积极推动Ray在分布式领域的突破与创新。',
    link_url: 'https://www.slidestalk.com/slidestalk/2hadoopmeetup20220968547?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/2hadoopmeetup20220968547?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/2hadoopmeetup20220968547'
  },
  {
    event_id: 1163,
    title: 'eBay Spark自动化升级之道',
    title_order: 400,
    content: 'eBay Hadoop team负责内部Spark版本的发布和维护，Spark的大版本升级不可避免会有些计算行为的改变，让用户自行做引擎版本的切换和数据校验，可想而知是一个漫长的过程。在最近的Spark 3 版本升级过程中，我们采用一套自动化系统进行SQL任务的数据校验和引擎版本的切换，用户只需要点击确认升级按钮，大大减少了用户和平台维护人员的人力成本。本次分享将系统的介绍eBay Spark自动化升级系统。',
    time: '15:00-15:30',
    name: '王 斐',
    avatar: '/images/second27.png',
    avatar_order: 300,
    branch: '1st',
    career: 'Apache Kyuubi PPMC Member，eBay Hadoop团队软件工程师',
    intro: 'Apache Kyuubi PPMC Member，eBay Hadoop团队软件工程师，负责离线计算平台的开发和维护',
    link_url: 'https://www.slidestalk.com/slidestalk/4ebay_spark_upgrade_automation11362?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/4ebay_spark_upgrade_automation11362?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/4ebay_spark_upgrade_automation11362'
  },
  {
    event_id: 1163,
    title: '',
    title_order: 550,
    content: '',
    time: '',
    name: '马洪宾',
    avatar: '/images/commitee29.png',
    avatar_order: 100,
    branch: '2nd',
    leader: true,
    career: 'Kyligence 技术合伙人，首席布道师及研究员',
    intro: ''
  },
  {
    event_id: 1163,
    title: 'openLooKeng的批处理特性探索',
    title_order: 200,
    content: 'ETL批处理是数据湖数据处理过程中的重要组成部分，对于长时间运行的任务，在失败时通过自动重试查询进行任务恢复，对于下游的业务来说非常重要。本议题将主要分享openLooKeng在批处理上面所做的一些探索，包括operator leve snapshot与task level snapshot等不同级别的容错恢复机制，并分享关于批处理的设计思考。',
    time: '14:00-14:30',
    name: '廖登宏',
    leader: true,
    avatar: '/images/fouth18.png',
    avatar_order: 200,
    branch: '2nd',
    career: '华为高级工程师',
    intro: '长期参与 openLooKeng 项目的需求分析、方案设计、代码开发等工作，致力于大数据领域的高性能查询场景的优化，对openLooKeng的解决方案及其行业应用有深入理解。',
    link_url: 'https://www.slidestalk.com/slidestalk/2openLooKeng23694?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/2openLooKeng23694?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/2openLooKeng23694'
  },
  {
    event_id: 1163,
    title: '更易用、更强劲的大数据分析平台 -- Kylin 5.0 社区路线一览',
    title_order: 300,
    content: '2021 年 9 月份，Apache Kylin 社区发布了 Kylin 的新一代产品 Kylin 4.0，使用 Parquet 替代 HBase 使得架构更加精简，实现了存算分离；目前在社区已经有很多用户选择升级到新的版本，获得了良好的使用体验。  与此同时，Kylin 社区也在酝酿着下一代 Kylin，一方面优化 Kylin 的预计算引擎和模型元数据，大大提升模型设计的灵活性；另一方面积极拥抱最新的技术潮流，从 Native engine、微服务等角度重新设计和实现 Kylin 的计算引擎和部署架构。在这次分享中，将会由 Kylin 社区的 PMC Member 俞霄翔来为大家带来下一代 Kylin 技术的前瞻性介绍和使用演示。',
    time: '14:30-15:00',
    name: '俞霄翔',
    avatar: '/images/first3.png',
    avatar_order: 800,
    branch: '2nd',
    career: 'Kyligence 高级研发工程师，Apache Kylin PMC Member',
    intro: 'Kyligence 高级研发工程师，Apache Kylin PMC Member，主导了 Kylin 3/4/5 的多个版本的开发和发布工作。',
    link_url: 'https://www.slidestalk.com/slidestalk/3HadoopMeetupXiaoxiangYu2022092484110?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/3HadoopMeetupXiaoxiangYu2022092484110?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/3HadoopMeetupXiaoxiangYu2022092484110'
  },
  {
    event_id: 1163,
    title: '哔哩哔哩如何基于Trino+Iceberg打造高效的湖仓一体平台',
    title_order: 400,
    content: '湖仓一体是近年来哔哩哔哩大数据平台一个重要的演进方向，如何基于湖仓一体降低数据分析的开发和查询成本，提升效率是我们持续探索的方向。本次分享主要介绍哔哩哔哩湖仓一体的技术架构，平台功能的建设，以及我们在数据管理服务，Trino引擎和Iceberg存储方向的技术探索。',
    time: '15:00-15:30',
    name: '李呈祥',
    avatar: '/images/first1.png',
    avatar_order: 300,
    branch: '2nd',
    career: '哔哩哔哩OLAP平台负责人',
    intro: '目前在哔哩哔哩负责OLAP平台的建设，有多年丰富的大数据内核和平台的研发经验。在此之前，他曾在阿里云，唯品会，Intel等多家公司负责或参与大数据平台和组件的研发工作，他是Apache Hive和Flink项目的committer。',
    link_url: 'https://www.slidestalk.com/slidestalk/4TrinoIceberg228071?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/4TrinoIceberg228071?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/4TrinoIceberg228071'
  },
  {
    event_id: 1163,
    title: 'Arctic 湖仓一体的践行之路',
    title_order: 700,
    content: '没有人怀疑，数据湖未来正在朝着湖仓一体的方向发展和演进，像 hudi、iceberg、delta 在过去两年也几乎成为大数据从业者耳熟能详的开源项目，但是这些技术真正作为 lakehouse 的解决方案，在价值吸引，落地成熟度上还存在不少的gap，网易数帆从2020年开始着手围绕 iceberg 构建流批一体和湖仓一体的技术和产品方案，为了最大限度实践并提升 lakehouse 的应用价值，我们研发了 arctic 这个项目，并在今年下半年开源，我们将在这次 talk 中聊聊 lakehouse 的发展，以及 arctic 在湖仓一体上的解法和思路。',
    time: '16:30-17:00',
    name: '马 进',
    avatar: '/images/first2.png',
    avatar_order: 500,
    branch: '2nd',
    career: '网易数帆大数据实时计算技术专家、湖仓一体项目负责人',
    intro: '网易数帆大数据实时计算技术专家、湖仓一体项目负责人，负责网易集团分布式数据库，数据传输平台，实时计算平台，实时数据湖等项目，长期从事中间件、大数据基础设施方面的研究和实践，目前带领团队聚焦于流批一体、湖仓一体的平台方案和技术演进，及流式湖仓服务 Arctic 项目开源。',
    link_url: 'https://www.slidestalk.com/slidestalk/6hadoopmeetup111504?video&embed',
    playback_url: 'https://www.slidestalk.com/slidestalk/6hadoopmeetup111504?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/6hadoopmeetup111504'
  },
  {
    event_id: 1163,
    title: '翼支付基于大数据生态构建企业级数据仓库的实践',
    title_order: 600,
    content: '1.翼支付数智中台体系介绍 2.基于大数据生态构建企业级数据仓库的挑战与实践 3.未来展望。',
    time: '16:00-16:30',
    name: '王文波',
    avatar: '/images/first4.png',
    avatar_order: 700,
    branch: '2nd',
    career: '翼支付数据总监',
    intro: '近10年的金融数据体系建设经验，曾任兴业银行零售数据集市负责人， 现任翼支付数据总监，负责公司数据仓库工作。',
    link_url: 'https://www.slidestalk.com/slidestalk/516670?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/516670?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/516670'
  },
  {
    event_id: 1163,
    title: '茶歇',
    title_order: 500,
    time: '15:30-16:00',
    avatar_order: 1500,
    branch: '2nd',
    career: '',
    intro: '',
    is_tea: true
  },
  {
    event_id: 1163,
    title: 'Apache Linkis(Incubating) 对接多样性计算存储引擎的技术实现和功能特性',
    title_order: 100,
    content: 'Apache Linkis(incubating)在上层应用程序和底层引擎之间构建了一层计算中间件，提供连通、管控、扩展、编排等计算治理能力。 在本次分享中，会以当前行业中对接多样性大数据计算存储引擎的痛点为切入，分享Linkis在技术上，如何实现对接多样性计算存储引擎，如何解决多租户隔离、资源管控等计算治理问题，同时会进行实际实际应用案例分享和未来规划。',
    time: '13:30-14:00',
    name: '夏 晨',
    avatar: '/images/second20.png',
    avatar_order: 750,
    branch: '2nd',
    career: 'Apache Linkis(Incubating) Committer',
    intro: 'Apache Linkis(Incubating) 社区核心开发人员。',
    link_url: 'https://www.slidestalk.com/slidestalk/1HadoopMeetup2022v0125964?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/1HadoopMeetup2022v0125964?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/1HadoopMeetup2022v0125964'
  },
  {
    event_id: 1163,
    title: 'Fluid—面向云原生数据密集型应用的高效开源支撑平台',
    title_order: 400,
    content: 'Fluid 一个支持数据密集型应用在云原生环境下高效运行的CNCF开源沙箱项目。其主要目标是解决在云原生运行环境下，大数据 / AI 等数据密集型应用面临的处理性能和使用便捷方面的问题。Fluid面向云原生数据密集型应用在数据抽象、弹性缓存、协同编排等几方面提出一系列技术创新，并在国内知名IT公司和行业龙头落地应用。本报告将会给大家介绍以数据密集型应用上云的挑战、Fluid的思想理念、技术方案、应用案例，以及最新发布的0.8版本的新功能。',
    time: '15:00-15:30',
    name: '顾 荣',
    avatar: '/images/commitee14.png',
    avatar_order: 100,
    branch: '3rd',
    leader: true,
    career: '南京大学副研究员，Fluid开源社区主席',
    intro: '南京大学副研究员，Fluid开源社区主席，Alluxio开源项目PMC成员，主要研究方向为云计算与大数据系统、高效缓存/索引系统、边缘计算系统等，主持多项国家基金项目和企业创新项目，发表学术论文40余篇，学术专著1部，成果落地于合作企业以及多个知名开源系统，获江苏省科学技术一等奖、腾讯云最具价值专家、中国尖峰开源人物、南京大学青年五四奖章等，任中国计算机学会高级会员、分布式计算与系统专委、系统软件专委执行委员。',
    playback_url: 'https://www.slidestalk.com/slidestalk/4FluidtalkRongGu55818?video&embed',
    link_url: 'https://www.slidestalk.com/slidestalk/4FluidtalkRongGu55818?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/4FluidtalkRongGu55818'
  },
  {
    event_id: 1163,
    title: '大数据应用在云原生处理器上的最优化实践',
    title_order: 100,
    content: '介绍在基于全新架构的云原生处理器平台上选择大数据应用的软件栈，构建和优化大数据应用的经验，分享优化后的应用表现数据。',
    time: '13:30-14:00',
    name: '费 斐',
    avatar: '/images/first5.jpg',
    avatar_order: 250,
    branch: '3rd',
    career: 'Ampere Computing软件部门资深首席工程师',
    intro: 'Ampere Computing公司软件部门的资深首席工程师，是一位计算机性能和软件技术专家，专注于多个性能相关领域，包括了虚拟化软件，Java语言应用，性能基准标准化和编译器相关优化，以及硬件系统未来性能模拟与预测等。',
    link_url: 'https://www.slidestalk.com/slidestalk/30165286?video&embed',
    playback_url: 'https://www.slidestalk.com/slidestalk/30165286?video&embed',
    download_url: null
  },
  {
    event_id: 1163,
    title: '字节跳动 Hadoop 云原生化演进实践',
    title_order: 200,
    content: 'Yodel（YARN on Gödel）是字节跳动提出的 Hadoop 大数据生态云原生化演进实践方案，其中 Gödel 是公司内增强版 Kubernetes ，目标将公司内大数据业务平滑迁移到 Kubernetes，实现在离线资源池统一，提升利用率。Yodel 基于 YARN 开发， 100% 兼容 Hadoop YARN 协议，用户可以像使用 YARN 一样使用 Yodel。Yodel 内部实现了 RGS（Remote Godel Scheduler）、 RKS （Remote Kubelet Service）等服务，RGS 维护资源请求并与 API Server 交互，将调度能力统一到 Godel Scheduler；RKS 实现了 YARN NM 所有接口，对用户和作业透明的前提下，把 NM 的 Container 管理能力平滑下沉到 Kubelet。目前 Yodel 已经在公司内得到大规模应用，升级过程对用户和任务平滑、无感。',
    time: '14:00-14:30',
    name: '林友权&邵凯阳',
    avatar: '/images/second26.png',
    avatar_order: 400,
    branch: '3rd',
    career: '火山引擎基础架构工程师',
    intro: '林友权毕业于广东华南理工大学，在字节跳动基础架构从事离线调度相关工作，具有多年 Hadoop 开发经验；邵凯阳毕业于北京航空航天大学，在字节跳动基础架构负责离线调度相关工作，具有多年工程架构经验。',
    link_url: 'https://www.slidestalk.com/slidestalk/2HadoopFinal47637?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/2HadoopFinal47637?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/2HadoopFinal47637'
  },
  {
    event_id: 1163,
    title: 'Apache Uniffle (Incubating) : 打造新一代通用Shuffle系统',
    title_order: 700,
    content: 'Apache Uniffle (Incubating) 的项目背景，架构，实现细节以及新特性。',
    time: '16:30-17:00',
    name: '齐 赫',
    avatar: '/images/second8.png',
    avatar_order: 500,
    branch: '3rd',
    career: 'Apache Uniffle (Incubating) PPMC',
    intro: '2017年硕士毕业于西安电子科技大学，加入百度基础架构团队，从事分布式计算调度相关工作。2020年加入腾讯数据湖团队，参与Spark及其周边生态系统的开发。',
    link_url: 'https://www.slidestalk.com/slidestalk/6Uniffle32864?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/6Uniffle32864?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/6Uniffle32864'
  },
  {
    event_id: 1163,
    title: 'Alluxio - 云原生数据编排平台',
    title_order: 600,
    content: '1. 回顾大数据/AI架构在过去十年间的演进历程，以及演进历程带来的新挑战；2. 介绍Alluxio数据编排项目，回顾Alluxio项目历史；3. Alluxio数据编排提供的统一数据视图；4. Alluxio在数据编排过程中提供的高效数据读写、便捷数据管理等功能；5. Alluxio对异构环境的支持；6. Alluxio的用户场景和案例简介',
    time: '16:00-16:30',
    name: '刘嘉承',
    avatar: '/images/second28.png',
    avatar_order: 300,
    branch: '3rd',
    career: 'Alluxio 核心开发工程师',
    intro: 'Alluxio 核心开发工程师，硕士毕业于哥伦比亚大学，目前就职于Alluxio公司研发团队核心组。发布了Alluxio K8s Helm Chart，参与并主导了一些Alluxio的RPC和内核优化工作。',
    link_url: 'https://www.slidestalk.com/slidestalk/5Alluxio66029?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/5Alluxio66029?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/5Alluxio66029'
  },
  {
    event_id: 1163,
    title: '茶歇',
    title_order: 500,
    time: '15:30-16:00',
    branch: '3rd',
    career: '',
    intro: '',
    is_tea: true
  },
  {
    event_id: 1163,
    title: '云原生的"消息、事件、流"融合处理平台-Apache RocketMQ',
    title_order: 300,
    content: '一、云原生Apache RocketMQ简介 ● 社区介绍 ● 存储架构介绍 二、RocketMQ数据集成建设 ● RocketMQ Streaming framework，流式数据计算能力 ● Flink connect，SQL计算能力 ● 数据集成能力，丰富的connector 三、云原生时代RocketMQ的演进 ● 云原生RocketMQ 5.0介绍： ● 生态 landscape。',
    time: '14:30-15:00',
    name: '丁 磊',
    avatar: '/images/second3.png',
    avatar_order: 200,
    branch: '3rd',
    career: 'Apache RocketMQ PMC Member',
    intro: 'Apache RocketMQ PMC Member',
    link_url: 'https://www.slidestalk.com/slidestalk/3__ApacheRocketMQ75153?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/3__ApacheRocketMQ75153?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/3__ApacheRocketMQ75153'
  },
  {
    event_id: 1163,
    title: 'DolphinScheduler调度机器学习场景任务',
    title_order: 600,
    content: 'Apache DolphinScheduler已经在 DataOps 领域提供了强大的分布式可视化工作流调度能力，2022年DolphinScheduler新增了AI任务调度能力，逐步开箱即用式地支持主流的MLops项目/服务商的功能（Jupyter, Mlflow, OpenMLDB，SageMaker等），提供易用，低成本，可视化的MLops技术栈编排能力，帮助企业提高机器学习模型生产的效率。同时基于DolphinScheduler已具备的强大大数据调度能力，能够打通机器AI平台和大数据平台。',
    time: '16:00-16:30',
    name: '周捷光',
    avatar: '/images/third3.png',
    avatar_order: 300,
    branch: '4th',
    career: '白鲸开源高级算法工程师',
    intro: '白鲸开源高级算法工程师，5年自然语言算法经验，目前在DolphinScheduler社群贡献机器学习任务调度组件。',
    link_url: 'https://www.slidestalk.com/slidestalk/5DolphinScheduler185464?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/5DolphinScheduler185464?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/5DolphinScheduler185464'
  },
  {
    event_id: 1163,
    title: '货拉拉大数据AI平台建设',
    title_order: 700,
    content: '1.特征数据应用的痛点；2.批流一体的特征平台建设；3.基于大数据的模型训练平台；4.未来的规划及演进。',
    time: '16:30-17:00',
    name: '黄 政',
    avatar: '/images/third40.jpg',
    avatar_order: 100,
    branch: '4th',
    career: '智能平台负责人&大数据专家',
    intro: '毕业于河海大学计算机学院，曾在香港城市大学研究模式识别方向，开发的跌倒检测产品获得企业投资。毕业后就职于腾讯公司，参与了腾讯云智能钛一站式机器学习平台（TI-One）和腾讯云智能钛弹性模型服务（TI-EMS）的研发工作，后加入货拉拉负责大数据AI平台方向。',
    link_url: 'https://www.slidestalk.com/slidestalk/6ApacheHadoop2022198001?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/6ApacheHadoop2022198001?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/6ApacheHadoop2022198001'
  },
  {
    event_id: 1163,
    title: 'Intel® 端到端AI 优化包助力人工智能“民主化”',
    title_order: 300,
    content: '现代人工智能系统通常需海量的多样化数据，繁琐的特征工程，复杂的深度学习模型以及昂贵的加速器或者GPU进行训练。这导致了复杂的软件架构，较高的准入门槛，昂贵的价格，从而给AI的开发和大规模部署带来了很大的局限。人工智能民主化（AI democratization)尝试从软硬件基础架构，数据处理，特征工程，模型开发共享调优等多个角度降低复杂AI解决方案的门槛来解决这些挑战和局限。 本演讲将介绍Intel®  端到端AI优化套件 (Intel® End-to-End AI Optimization Kit)以及如何用它来助力AI“民主化”。 首先我们将介绍当前人工智能尤其复杂深度学习方案的挑战，然后我们将介绍“民主化”的目标和方法。接下来会介绍Intel®  端到端AI优化套件及其主要部件，包括数据处理，数据连接器，智能模型调优器，紧凑模型构建器等。最后将通过多个模型优化前与优化后的性能对比 - 在保持模型精度不变或者非常接近的情况下，通过简单易用的工作流带来端到端时间上数十倍的提升，从而使得在CPU集群上进行深度学习模型开发，优化部署变得更加高效。',
    time: '14:30-15:00',
    name: '张 建',
    avatar: '/images/third2.png',
    avatar_order: 200,
    branch: '4th',
    career: '英特尔人工智能软件工程经理',
    intro: '英特尔的一名人工智能软件工程经理，他和他的团队主要专注于英特尔平台上的端到端数据分析和人工智能工作流优化，开发了Intel® 端到端AI优化套件，使得基于分布式 CPU 集群开发，优化和部署端到端人工智能解决方案更加简单高效，提高人工智能方案的可扩展性，易用性及普遍性。 此前，他曾专注于多个虚拟化，云计算，分布式存储，大数据处理开源项目的开发与性能优化。',
    link_url: 'https://www.slidestalk.com/slidestalk/32022_09_HadoopMeetupDataplatformforendtoendAIv0517169?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/32022_09_HadoopMeetupDataplatformforendtoendAIv0517169?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/32022_09_HadoopMeetupDataplatformforendtoendAIv0517169'
  },
  {
    event_id: 1163,
    title: '翼支付在Neural Architecture Search领域的探索与实践',
    title_order: 200,
    content: '1. 介绍 AutoML 领域的研究与产业化现状；2. 介绍翼支付在 Neural Architecture Search 的探索与实践；3. 展望未来。',
    time: '14:00-14:30',
    name: '谢巍盛',
    avatar: '/images/third20.png',
    avatar_order: 80,
    branch: '4th',
    leader: true,
    career: '翼支付首席数据科学家、人工智能研究院院长',
    intro: '翼支付首席数据科学家, 人工智能研究院院长, 负责数智科技能力建设及产业化工作。',
    link_url: 'https://www.slidestalk.com/slidestalk/240952?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/240952?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/240952'
  },
  {
    event_id: 1163,
    title: '茶歇',
    title_order: 500,
    time: '15:30-16:00',
    avatar_order: 400,
    branch: '4th',
    career: '',
    intro: '',
    is_tea: true
  },
  {
    event_id: 1163,
    title: '大数据AI助力金融智能化转型',
    title_order: 100,
    content: '平安银行零售智能化银行转型过程中，遇到的困难重重，在“五位一体”战略下，通过大数据和AI能力建设快速支持业务转型，主要分享大数据AI中台架构设计及业务场景。',
    time: '13:30-14:00',
    name: '廖晓格',
    avatar: '/images/third30.png',
    avatar_order: 180,
    branch: '4th',
    career: '平安银行大数据基础平台&AI部落负责人',
    intro: '平安银行大数据基础平台&AI部落负责人，负责平安银行AI平台的建设，推动银行零售智能化转型，曾就职于华为、携程、eBay、PPTV和泰为，有多年大数据平台及AI平台的研发经验。',
    link_url: 'https://www.slidestalk.com/slidestalk/1ApacheHadoopMeetup0922118232?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/1ApacheHadoopMeetup0922118232?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/1ApacheHadoopMeetup0922118232'
  },
  {
    event_id: 1163,
    title: '使用BigDL构建和扩展大规模端到端的人工智能应用',
    title_order: 400,
    content: '将AI模型集成到数据分析的流水线往往是构建大规模智能系统的关键一环。当数据科学家和AI工程师们用深度学习框架开发完程序之后，迁移到生产环境中部署的过程中会遇到很多挑战（不少的代码改动、如何充分利用硬件资源等）。为此我们开源了BigDL 2.0 (https:/ /github.com/intel-analytics/BigDL/)，用户能使用BigDL-Nano透明地显著加速单机AI应用的开发，并且使用BigDL-Orca无缝地把解决方案扩展到大数据集群上。BigDL同时也对许多垂直领域（比如隐私保护的机器学习、推荐等）提供了现成的大规模解决方案。目前BigDL已经被很多企业（比如MasterCard、汉堡王、浪潮等）用户成功落地使用。本次议题将主要介绍BigDL提供的功能模块，以及分享BigDL的实际用户案例和经验。',
    time: '15:00-15:30',
    name: '黄 凯',
    avatar: '/images/third50.jpg',
    avatar_order: 90,
    branch: '4th',
    career: 'Intel人工智能框架工程师',
    intro: 'Intel人工智能框架工程师。主要负责开发基于Apache Spark的深度学习框架，成功支持了很多企业客户在大数据平台上构建端到端的深度学习应用。他是BigDL的核心贡献者之一。',
    link_url: 'https://www.slidestalk.com/slidestalk/4bigdl46902?video&embed',
    playback_url: 'https://www.slidestalk.com/slidestalk/4bigdl46902?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/4bigdl46902'
  },
  {
    event_id: 1163,
    title: 'AI 数据开放的现状与治理',
    title_order: 300,
    content: '数据是 AI 发展的重要基础设施，而针对AI 数据的开放共享，当前现状如何？又有哪些可以提升其开放性的工作应当被推进呢？在此次演讲中我们将基于我们开展中的《中国 AI 数据开放现状及案例》的研究，和大家分享当前中国 AI 数据集的打造和分享现况，并以数据开放协议这一具体议题作为案例探讨社区可以如何推进和加速 AI 数据的打造和开放共享。',
    time: '14:30-15:00',
    name: '高 丰',
    leader: true,
    avatar: '/images/commitee10.png',
    avatar_order: 100,
    branch: '5th',
    career: '开放数据中国执行主任、白玉兰开源开放数据顾问',
    intro: '开放数据中国联合创始人兼执行主任，兼任上海白玉兰开源开放研究院开放数据顾问。作为开放数据议题的先锋推动者，他先后被授予耶鲁大学世界学者、第一财经 DT 财经“数据科学 50 人”等荣誉。',
    link_url: 'https://www.slidestalk.com/slidestalk/3AI54358?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/3AI54358?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/3AI54358'
  },
  {
    event_id: 1163,
    title: '建设开源数据生态体系，助力高水平科技创新',
    title_order: 600,
    content: 'OpenDataLab 以统一标准构建开放数据集互联互通网络，是下一代人功能智能交叉创新和技术发展的数据底座。OpenDataLab围绕开源数据建设开源数据生态体系，通过打通数据资源互联互通，开放数据协议支撑，数据集标准化语言构建，智能标注工具辅助，助力高水平科技创新。',
    time: '16:00-16:30',
    name: '李 薇',
    avatar: '/images/fouth30.png',
    avatar_order: 300,
    branch: '5th',
    career: 'OpenDataLab 产品主管',
    intro: '致力于人工智能数据基础设施建设，引领AI数据开源开放。',
    link_url: 'https://www.slidestalk.com/slidestalk/5Apachemeetupv114172?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/5Apachemeetupv114172?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/5Apachemeetupv114172'
    
  },
  {
    event_id: 1163,
    title: 'OpenDataology数据合规项目介绍及案例分享',
    title_order: 100,
    content: '目前大量的公开数据集和模型因来源不明、侵犯个人隐私、许可条款不清晰等问题阻碍了AI科研创新和行业应用落地，可能造成AI产品无法通过可信评估。本Session将为受众带来开源全流程AI数据合规方案OpenDataology。特别介绍典型的AI数据集和模型可信问题场景，如何利用OpenDataology提供的数据可信指南、模型元数据信息检索、数据可信风险扫描、许可证推荐等服务使能科研工作者、AI应用开发者数据可信使用AI技术。并分享MindSpore羲和大模型平台落地发布案例。',
    time: '13:30-14:00',
    name: '李 自',
    avatar: '/images/fouth40.png',
    avatar_order: 400,
    branch: '5th',
    career: '华为OpenDataology社区PMC',
    intro: 'LF-AI&DATA基金会项目OpenDataology社区发起人，拥有5年企业开源治理经验，聚焦开源流程与工具体系，在开源软件和数据合规方面有深入研究。',
    link_url: 'https://www.slidestalk.com/slidestalk/1OpenDataologyAI231188?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/1OpenDataologyAI231188?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/1OpenDataologyAI231188'
  },
  {
    event_id: 1163,
    title: '茶歇',
    title_order: 500,
    time: '15:30-16:00',
    avatar_order: 400,
    branch: '5th',
    career: '',
    intro: '',
    is_tea: true
  },
  {
    event_id: 1163,
    title: '开放数据创新——如何通过开源社区共建数据要素市场生态',
    title_order: 400,
    content: '围绕开放群岛的社区实践，介绍一种开放数据创新的新范式。在生态建设上，依托深圳数据交易有限公司，联合50家国家单位、智库、高校、大型金融机构、头部企业共同发起社区；在场景应用上，充分利用深圳自主创新的先进机制和产业优势，以服务全国数据要素流通应用场景为目标；在协作方式上，通过“隐私计算开源协同计划”打破数据孤岛、平台孤岛，乃至开源平台孤岛，促进隐私计算平台互联互通。',
    time: '15:00-15:30',
    name: '王青兰',
    avatar: '/images/fouth50.png',
    avatar_order: 500,
    branch: '5th',
    career: '开放群岛开源社区法律合规组组长',
    intro: '法学博士，计算机科学与技术博士后。现任北鹏前沿科技法律研究院理事、副院长，北京大学深圳研究院、深港产学研基地前沿科技与政策法律研究团队负责人，中国计算机行业协会数据安全产业专家委员会委员，中国科协决策咨询专家团队建设试点、中国通信学会网络与数据法治决策咨询专家，深圳市计算机学会区块链专委会副主任，中国首个国际化自主可控隐私计算开源社区“开放群岛”（Open Islands）社区委员会法律合规组组长，中国网络安全审查技术与认证中心“个人信息保护专业人员(CISP-PIP)”资质评定讲师等。',
    link_url: 'https://www.slidestalk.com/slidestalk/4Hadoop73458?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/4Hadoop73458?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/4Hadoop73458'
  },
  {
    event_id: 1163,
    title: 'ChunJun 数据集成在数据还原上的探索和实践',
    title_order: 700,
    content: 'ChunJun 是实践过程沉淀和完善的数据集成工具，在经过4年多的开发和迭代之后，已经具备了稳定、高效、易用等特性，在袋鼠云的生产环境上稳定运行；同时ChunJun是基于flink进行的扩展，具备flink 分布式和易扩展，同时可以使用sql进行数据清洗。 Github：https://github.com/DTStack/chunjun 此次分享的内容《ChunJun 数据集成在数据还原上的探索和实践》将会按照以下几点来分享： 一、ChunJun 介绍  围绕ChunJun的开源发展历程、产品特性、主要功能作用等方面来介绍，更直观的了解ChunJun。 二、ChunJun在实时DML上的原理和实现 基于多种不同数据源上开发的DML数据监听并还原到下有目标数据源 三、ChunJun在实时DDL上的原理和实现 基于多种不同数据源上开发的DDL表结构变更的监听并还原到下有目标数据源 四、ChuJun未来的规划 1、包括未来会提供对Session 进行管理；用户只需要进行配置，不需要关心远程的环境信息，专注于任务信息的配置； 2、提供restful 服务，ChunJun 本身作为一个服务，便于外围系统进行集成 3、对实时数据还原进行加强，包括扩展支持更多的数据源的DDL 解析。',
    time: '16:30-17:00',
    name: '徐 超',
    avatar: '/images/fouth70.png',
    avatar_order: 550,
    branch: '5th',
    career: '袋鼠云资深大数据引擎开发专家，ChunJun社区负责人',
    intro: '徐超（花名小刀）在袋鼠云负责计算引擎团队，团队主要针对Flink、Spark、Trino 等计算引擎进行二次开发相关。从内部孵化了ChunJun（在github 上3.2k Star）：易用、稳定、高效的批流统一的数据集成框架；FlinkStreamSQL(在github 上1.8K Star): 基于开源的flink，对其实时sql进行扩展；主要实现了流与维表的join，支持原生flink SQL所有的语法。参与过Flink meetup 分享，多场外部技术论坛等活动。',
    link_url: 'https://www.slidestalk.com/slidestalk/6ChunJun96161?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/6ChunJun96161?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/6ChunJun96161'
  },
  {
    event_id: 1163,
    title: 'Magichub赋能数据开源开放',
    title_order: 200,
    content: '开源是推动科技创新的抓手，MagicHub开源社区秉持“共享共建，奉献创新、共同发展”精神，为行业提供开源AI训练数据。目前社区覆盖全球5000名AI开发者，开源100多组数据集，以对话式数据集为主，包括ASR、NLP、TTS等数据类型。另外，在语种上覆盖60种外语和30+方言数据集。Magichub社区秉承为开源而生的价值理念， 鼓励数据所有者在社区开源数据集，与开发者共享。未来，将有更多数据集开源到社区，造福AI开发者。',
    time: '14:00-14:30',
    name: '张晴晴',
    avatar: '/images/fouth60.png',
    avatar_order: 600,
    branch: '5th',
    career: 'Magic Data创始人兼CEO',
    intro: 'Magic Data 创始人兼CEO  •语音技术专家、对话式AI先行者 •中科院声学所副研究员、博士 •法国国家实验室LIMSI-CNRS博士后 •获得《财富》2021年中国最具影响力的商界女性未来榜 •创业邦2021年最值得关注的女性创业者 •CCF语音对话与听觉专委会委员 •CCF智能汽车分会执行委员 •CCF女计算机工作者委员会委员 •中国科学院杰出科技成就奖 •微软亚洲研究院“微软学者”奖学金获得者。',
    link_url: 'https://www.slidestalk.com/slidestalk/2ApacheHadoopMagicHub95247?video',
    playback_url: 'https://www.slidestalk.com/slidestalk/2ApacheHadoopMagicHub95247?video&embed',
    download_url: 'https://www.slidestalk.com/slides/download/2ApacheHadoopMagicHub95247'
  }
]

/**
 * 品牌方
 */
export const BRANDCENTER = [
  {
    event_id: 1163,
    picture: '/images/logo-owner170.png',
    branch: 'owner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-direct4.png',
    branch: 'direct'
  },
  {
    event_id: 1163,
    picture: '/images/logo-direct2.png',
    branch: 'direct'
  },
  {
    event_id: 1163,
    picture: '/images/logo-direct3.png',
    branch: 'direct'
  },
  {
    event_id: 1163,
    picture: '/images/logo-undertake1.png',
    branch: 'undertake'
  },
  {
    event_id: 1163,
    picture: '/images/logo-policy1.png',
    branch: 'policy'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner1.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner2.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner3.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner4.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner5.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner66.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner7.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner8.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner9.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner10.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner20.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner30.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner40.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner50.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner180.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner70.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner80.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner90.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner100.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner170.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner120.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner130.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner140.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner160.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner150.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-owner2.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner181.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner190.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner200.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner210.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner215.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner220.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner225.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner230.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner235.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo-partner240.png',
    branch: 'partner'
  },
  {
    event_id: 1163,
    picture: '/images/invite1.png',
    branch: 'fire'
  },
  {
    event_id: 1163,
    picture: '/images/logo-fire1.png',
    branch: 'fire'
  },
  {
    event_id: 1163,
    picture: '/images/invite2.png',
    branch: 'invite_partner'
  },
  {
    event_id: 1163,
    picture: '/images/invite3.png',
    branch: 'invite_partner'
  },
  {
    event_id: 1163,
    picture: '/images/logo0.png',
    branch: 'live'
  }
]

/**
 * banner图片
 */
export const BANNERCENTER = [
  {
    msrc: '/images/banner-m.jpg?4',
    pcsrc: '/images/banner-pc.jpg?4',
    url: ''
  },
  {
    msrc: '/images/banner-ampere-m2.jpg?4',
    pcsrc: '/images/banner-ampere-pc2.jpg?4',
    url: 'https://amperecomputing.com'
  },
  {
    msrc: '/images/banner-Hadoop-m.jpg?4',
    pcsrc: '/images/banner-Hadoop-pc.jpg?4',
    url: 'https://openlookeng.io/'
  },
  {
    msrc: '/images/banner-jihu-gitlab-m2.jpg?4',
    pcsrc: '/images/banner-jihu-gitlab-pc2.jpg?4',
    url: 'https://gitlab.cn?utm_source=hd2022'
  }
]
